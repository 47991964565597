var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { lg: 14, md: 11, sm: 24, xl: 14, xs: 24 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符"),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { lg: 9, md: 12, sm: 24, xl: 9, xs: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "login-form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "left",
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("hello !")]),
                  _c("div", { staticClass: "title-tips" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.translateTitle("欢迎来到")) +
                        _vm._s(_vm.title) +
                        "！ "
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "40px" },
                      attrs: { prop: "username" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          attrs: {
                            placeholder: _vm.translateTitle("请输入用户名"),
                            tabindex: "1",
                            type: "text",
                          },
                          model: {
                            value: _vm.form.username,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "username",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.username",
                          },
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { slot: "prefix", icon: "user-line" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "el-input",
                        {
                          key: _vm.passwordType,
                          ref: "password",
                          attrs: {
                            placeholder: _vm.translateTitle("请输入密码"),
                            type: _vm.passwordType,
                            tabindex: "2",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleLogin.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.password",
                          },
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { slot: "prefix", icon: "lock-line" },
                            slot: "prefix",
                          }),
                          _vm.passwordType === "password"
                            ? _c("vab-remix-icon", {
                                staticClass: "show-password",
                                attrs: { slot: "suffix", icon: "eye-off-line" },
                                on: { click: _vm.handlePassword },
                                slot: "suffix",
                              })
                            : _c("vab-remix-icon", {
                                staticClass: "show-password",
                                attrs: { slot: "suffix", icon: "eye-line" },
                                on: { click: _vm.handlePassword },
                                slot: "suffix",
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: { "margin-bottom": "10px", color: "#fff" },
                      model: {
                        value: _vm.spw,
                        callback: function ($$v) {
                          _vm.spw = $$v
                        },
                        expression: "spw",
                      },
                    },
                    [_vm._v(" 记住密码 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "login-btn",
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: { click: _vm.handleLogin },
                    },
                    [_vm._v(" " + _vm._s(_vm.translateTitle("登录")) + " ")]
                  ),
                  _c("router-link", { attrs: { to: "/register" } }, [
                    _c("div", { staticStyle: { "margin-top": "20px" } }, [
                      _vm._v(_vm._s(_vm.translateTitle("注册"))),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { lg: 1, md: 1, sm: 24, xl: 1, xs: 24 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符"),
            ]),
          ]),
        ],
        1
      ),
      _c("footer", { staticClass: "footer-copyright" }, [
        _c(
          "div",
          {
            staticStyle: {
              "padding-bottom": "5px",
              "line-height": "0.5",
              "text-align": "center",
            },
          },
          [
            _vm._m(0),
            _vm._m(1),
            _c("vab-remix-icon", {
              staticStyle: { color: "#fff" },
              attrs: { icon: "copyright-line" },
            }),
            _c("span", { staticStyle: { color: "#fff" } }, [
              _vm._v(_vm._s(_vm.copyright)),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { color: "#000" } }, [
      _c(
        "a",
        { attrs: { target: "_blank", href: "https://beian.miit.gov.cn" } },
        [_vm._v(" 冀ICP备15014280号 ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { margin: "0 auto", padding: "0 0 10px" } },
      [
        _c(
          "a",
          {
            staticStyle: {
              display: "inline-block",
              "text-decoration": "none",
              height: "20px",
              "line-height": "20px",
            },
            attrs: {
              target: "_blank",
              href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010202002911",
            },
          },
          [
            _c("img", {
              staticStyle: { float: "left" },
              attrs: { src: "https://down.sd32.cn/gongan.png" },
            }),
            _c(
              "p",
              {
                staticStyle: {
                  float: "left",
                  height: "20px",
                  "line-height": "20px",
                  margin: "0px 0px 0px 5px",
                  color: "#939393",
                },
              },
              [_vm._v(" 冀公网安备 13010202002911号 ")]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }