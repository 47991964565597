<template>
  <div class="login-container">
    <el-row>
      <el-col :lg="14" :md="11" :sm="24" :xl="14" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :lg="9" :md="12" :sm="24" :xl="9" :xs="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          class="login-form"
          label-position="left"
        >
          <div class="title">hello !</div>
          <div class="title-tips">
            {{ translateTitle('欢迎来到') }}{{ title }}！
          </div>
          <!-- <el-form-item prop="dealer_no" style="margin-top: 40px">
            <el-input
              v-model.trim="form.dealer_no"
              v-focus
              :placeholder="translateTitle('商家编号')"
              tabindex="1"
              type="text"
            >
              <vab-remix-icon slot="prefix" icon="user-line" />
            </el-input>
          </el-form-item> -->
          <el-form-item prop="username" style="margin-top: 40px">
            <el-input
              v-model.trim="form.username"
              v-focus
              :placeholder="translateTitle('请输入用户名')"
              tabindex="1"
              type="text"
            >
              <vab-remix-icon slot="prefix" icon="user-line" />
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :key="passwordType"
              ref="password"
              v-model.trim="form.password"
              :placeholder="translateTitle('请输入密码')"
              :type="passwordType"
              tabindex="2"
              @keyup.enter.native="handleLogin"
            >
              <vab-remix-icon slot="prefix" icon="lock-line" />
              <vab-remix-icon
                v-if="passwordType === 'password'"
                slot="suffix"
                class="show-password"
                icon="eye-off-line"
                @click="handlePassword"
              />
              <vab-remix-icon
                v-else
                slot="suffix"
                class="show-password"
                icon="eye-line"
                @click="handlePassword"
              />
            </el-input>
          </el-form-item>
          <el-checkbox v-model="spw" style="margin-bottom: 10px; color: #fff">
            记住密码
          </el-checkbox>
          <el-button
            :loading="loading"
            class="login-btn"
            type="primary"
            @click="handleLogin"
          >
            {{ translateTitle('登录') }}
          </el-button>

          <router-link to="/register">
            <div style="margin-top: 20px">{{ translateTitle('注册') }}</div>
          </router-link>
        </el-form>
      </el-col>
      <el-col :lg="1" :md="1" :sm="24" :xl="1" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
    </el-row>
    <footer class="footer-copyright">
      <div style="padding-bottom: 5px; line-height: 0.5; text-align: center">
        <p style="color: #000">
          <a target="_blank" href="https://beian.miit.gov.cn">
            冀ICP备15014280号
          </a>
        </p>
        <div style="margin: 0 auto; padding: 0 0 10px">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010202002911"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "
          >
            <img src="https://down.sd32.cn/gongan.png" style="float: left" />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
              "
            >
              冀公网安备 13010202002911号
            </p>
          </a>
        </div>
        <!-- <p>https://beian.miit.gov.cn/</p> -->
        <!-- <p style="color: #000">
          <a target="_blank" href="https://down.sd32.cn/cert.jpg">
            增值电信业务经营许可证：冀B2-20210451
          </a>
        </p> -->
        <!-- <p>https://down.sd32.cn/cert.jpg</p> -->
        <!-- <p>
          <a
            target="_blank"
            style="color: #fff"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010202002892"
          >
            冀公网安备 13010202002892号
          </a>
        </p> -->
        <!-- <p>
            http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010202002892
          </p> -->
        <vab-remix-icon icon="copyright-line" style="color: #fff" />
        <span style="color: #fff">{{ copyright }}</span>
      </div>
    </footer>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { translateTitle } from '@/utils/i18n'
  import { isPassword } from '@/utils/validate'
  import { copyright } from '@/config'

  export default {
    name: 'Login',
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    beforeRouteLeave(to, from, next) {
      clearInterval(this.timer)
      next()
    },
    data() {
      const validateUsername = (rule, value, callback) => {
        if ('' === value) {
          callback(new Error(this.translateTitle('用户名不能为空')))
        } else {
          callback()
        }
      }
      const validateDealerNo = (rule, value, callback) => {
        if ('' === value) {
          callback(new Error(this.translateTitle('商家编号不能为空')))
        } else {
          callback()
        }
      }
      const validatePassword = (rule, value, callback) => {
        if (!isPassword(value)) {
          callback(new Error(this.translateTitle('密码不能少于5位')))
        } else {
          callback()
        }
      }
      return {
        nodeEnv: process.env.NODE_ENV,
        spw: false,
        copyright,
        form: {
          username: '',
          password: '',
          dealer_no: '',
          platform: '5',
        },
        rules: {
          username: [
            {
              required: true,
              trigger: 'blur',
              validator: validateUsername,
            },
          ],
          dealer_no: [
            {
              required: true,
              trigger: 'blur',
              validator: validateDealerNo,
            },
          ],
          password: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
        },
        loading: false,
        passwordType: 'password',
        redirect: undefined,
        timer: 0,
      }
    },
    computed: {
      ...mapGetters({
        title: 'settings/title',
      }),
    },
    watch: {
      $route: {
        handler(route) {
          this.redirect = (route.query && route.query.redirect) || '/'
        },
        immediate: true,
      },
    },

    created() {
      document.body.style.overflow = 'hidden'
    },
    beforeDestroy() {
      document.body.style.overflow = 'auto'
    },
    mounted() {
      let u = window.localStorage.username || ''
      let p = window.localStorage.password || ''
      this.form.username = u
      this.form.password = p
      //为了演示效果，自动登录到首页
      // this.timer = setTimeout(() => {
      //   this.handleLogin()
      // }, 5000)
    },
    methods: {
      ...mapActions({
        login: 'user/login',
      }),
      translateTitle,
      handlePassword() {
        this.passwordType === 'password'
          ? (this.passwordType = '')
          : (this.passwordType = 'password')
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleRoute() {
        return this.redirect === '/404' || this.redirect === '/403'
          ? '/'
          : this.redirect
      },
      handleLogin() {
        console.log(this.form)
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            try {
              this.loading = true
              await this.login(this.form)
              // await this.$router.push(this.handleRoute())
              await this.$router.push('/')
              window.localStorage.username = this.form.username
              if (this.spw) {
                // 保存密码
                console.log('stor', window.localStorage)
                window.localStorage.password = this.form.password
              } else {
                window.localStorage.password = ''
              }
            } finally {
              this.loading = false
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .login-container {
    height: 100vh;
    background: url('~@/assets/login_images/background.jpg') center center fixed
      no-repeat;
    background-size: cover;
  }

  .login-form {
    position: relative;
    max-width: 100%;
    padding: 4.5vh;
    margin: calc((100vh - 475px) / 2) 5vw 5vw;
    overflow: hidden;
    background: url('~@/assets/login_images/login_form.png');
    background-size: 100% 100%;

    .title {
      font-size: 54px;
      font-weight: 500;
      color: $base-color-white;
    }

    .title-tips {
      margin-top: 29px;
      font-size: 26px;
      font-weight: 400;
      color: $base-color-white;
    }

    .login-btn {
      display: inherit;
      width: 220px;
      height: 50px;
      margin-top: 5px;
      border: 0;

      &:hover {
        opacity: 0.9;
      }

      .forget-passwordword {
        width: 100%;
        margin-top: 40px;
        text-align: left;

        .forget-password {
          width: 129px;
          height: 19px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(92, 102, 240, 1);
        }
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: $base-font-size-default;
      color: $base-color-white;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        margin: 0 auto 40px auto;
        font-size: 34px;
        font-weight: bold;
        color: $base-color-blue;
        text-align: center;
      }
    }

    i {
      position: absolute;
      top: 8px;
      left: 5px;
      z-index: $base-z-index;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    .show-password {
      position: absolute;
      right: 25px;
      left: -35px;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    ::v-deep {
      .el-form-item {
        padding-right: 0;
        margin: 20px 0;
        color: #454545;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 2px;

        &__content {
          min-height: $base-input-height;
          line-height: $base-input-height;
        }

        &__error {
          position: absolute;
          top: 100%;
          left: 18px;
          font-size: $base-font-size-small;
          line-height: 18px;
          color: $base-color-red;
        }
      }

      .el-input {
        box-sizing: border-box;

        input {
          height: 48px;
          padding-left: 35px;
          font-size: $base-font-size-default;
          line-height: 58px;
          color: $base-font-color;
          background: #f6f4fc;
          border: 0;
          caret-color: $base-font-color;
        }
      }
    }
    .footer-copyright {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 55px;
      padding: 0 $base-padding 0 $base-padding;
      color: rgba(0, 0, 0, 0.45);
      border-top: 1px dashed $base-border-color;
      a {
        color: #000;
      }
      i {
        margin: 0 5px;
      }
    }
  }
</style>
